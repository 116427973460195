import axios from "axios";
const serverUrl = "https://kiltapp.kz";
const cloudImageUrl = "https://imagedelivery.net/ZTlxMKuyZ70Mcpw4MQw_LA";
const cdnImageUrl = "https://kilt-cdn.kz";
// const serverUrl = "http://localhost:3000";
// const serverUrl = "http://192.168.0.126:3000";

const makeRequest = async (method, url, params, headers = {}) => {
  // const authStore = store.$stores.authentication
  const apiUrl = `${serverUrl}/api`;
  console.log("apiUrl: ", apiUrl);
  //const deviceHeader = getDeviceHeader()
  // let appCheckToken = null

  // if (Platform.OS === 'android') {
  //   try {
  //     appCheckToken = await appCheck.getToken(false)
  //     console.log('[app-check] token =', appCheckToken)
  //   } catch(ex) {
  //     // do nothing, it's ok fail here on dev devices
  //     console.log('[app-check] failed token request')
  //   }
  // }

  // if (!isValidToken && needToken(url)) {
  //   const unauthorizedError = {
  //     response: {
  //       data: {
  //         code: 401,
  //         msg: 'Unauthorized',
  //       },
  //     },
  //   }

  //   return Promise.reject(unauthorizedError)
  // }

  const options = {
    url: `${apiUrl}/v1${url}`,
    method,
    headers,
  };

  if (method === "get") {
    options.params = params;
  } else {
    options.data = params;
  }

  // const { token, secondtoken } = authStore

  // if (token) options.headers.token = token
  // if (secondtoken) {
  //   options.headers.secondtoken = secondtoken
  //   authStore.setSecondToken(null)
  // }

  return axios(options);
};

const request =
  (method, url) =>
    (...params) => {
      console.log(method, url);

      return makeRequest(method, url, ...params)
        .then(function (response) {
          //if (['/users/login', '/users/refresh-token'].includes(url)) isValidToken = true

          return response;
        })
        .catch(function (error) {
          // const settingsStore = store.$stores.settingsStore

          // if (error.message === 'Network Error' && settingsStore.appState === 'active') {
          //   store.$stores.toast.show('', i18n.t('api_error.internet_error'), 'error')
          //   store.$stores.settings.lostInternet()
          // }

          // if (error.response.data.code === 401) {
          //   isValidToken = false

          //   const authStore = store.$stores.authentication

          //   authStore.token = null

          //TODO fix this
          // const route = navigation.getCurrentRoute(navigation.state);

          // if (needToken(url) && route?.routeName !== "SignUp") {
          //   reset(["Main", "SignUp"]);
          // }
          //}

          // console.log(method, url, error, " - apiMiddleware error");
          return Promise.reject(error);
        });
    };

const usersApi = {
  generateOTP: (body) => request("post", "/users/generate-otp")(body),
  checkRole: ({ id, body }) => request("post", `/users/check-role/${id}`)(body),
  checkOTP: (body) => request("post", "/users/check-otp")(body),
  saveFCMToken: (body) => request("post", "/users/save-fcm-token")(body),
  deleteAccount: (id) => request("get", `/users/delete/${id}`)(),
  deleteConfirm: (id, code) =>
    request("get", `/users/delete-confirm/${id}?code=${code}`)(),
  updateProfile: (id, body) => request("post", `/users/update/${id}`)(body),
  getBonus: (user_id) => request("get", `/bonus/my-balance/${user_id}`)(),
};

const bonusApi = {
  transaction: (body) => request("post", "/bonus/transaction")(body),
};

const listingApi = {
  listingsToModerate: () => request("post", `/listings/listings-to-moderate`)(),
  getListing: (id) => request("get", `/listings/${id}`)(),
  updateListing: ({ id, body }) =>
    request("post", `/listings/update/${id}`)(body),
  getConfig: () => request("get", "/listings/config")(),
  search: (body) => request("post", "/listings/search")(body),
  addListing: (body) => request("post", "/listings/add")(body),
  editListing: (body) => request("post", "/listings/edit")(body),
  addImages: (body) =>
    request("post", "/listings/images")(body, {
      "Content-Type": "multipart/form-data",
    }),
  listingsOfResComplex: (id) =>
    request("get", `/listings/residential-complex/${id}`)(),
  getSimilar: ({ id, body }) =>
    request("post", `/listings/similar/${id}`)(body),
  promote: (body) => request("post", "/listings/promote")(body),
  propertiesForRent: (id) => request("get", `/listings/for-rent/${id}`)(),
  propertiesOfSeller: (id) => request("get", `/listings/seller/${id}`)(),
  searchBuildingComplexes: (body) =>
    request("post", "/listings/building-complexes/")(body),
  getResultsCount: (body) => request("post", "/listings/count-search")(body),
  getWhatsappText: (body) => request("post", "/listings/whatsapp-text")(body),
  updateImages: (body) => request("post", "/listings/update-images")(body),
};

const residentialComplexApi = {
  getResComplex: (id) => request("get", `/residential-complex/${id}`)(),
  getResComplexes: (startsWith) =>
    request("get", `/residential-complex/all?starts=${startsWith}`)(),
  residentialComplexesOfDeveloper: (id) =>
    request("get", `/residential-complex/developer/${id}`)(),
};

const developerApi = {
  getDeveloper: (id) => request("get", `/developer/${id}`)(),
};

const katoApi = {
  getKatoByParent: (id) => request("get", `/kato/parent/${id}`)(),
  getKatoByParentArray: (id) => request("get", `/kato/parent-array/${id}`)(),
};

const partnersApi = {
  getAll: () => request("get", `/partner/all`)(),
  getProducts: (id) => request("get", `/partner/products/${id}`)(),
};

const campaignDataApi = {
  getAll: (userId) => request("get", `/campaign-data/all?user_id=${userId}`)(),
};

const dealsApi = {
  createDeal: (body) => request("post", "/deals/create")(body),
  getDeal: (id) => request("get", `/deals/${id}`)(),
  fillData: (id, type, body) =>
    request("post", `/deals/fill/${id}?type=${type}`)(body),
  updateDeal: (id, body) => request("post", `/deals/update/${id}`)(body),
  createSession: (id, type) =>
    request("get", `/deals/create-session/${id}?type=${type}`)(),
  fillAndSign: (template, user_type, body, deal_id) =>
    request(
      "post",
      `/deals/fill-and-sign?template=${template}&user_type=${user_type}${!!deal_id ? `&deal_id=${deal_id}` : ""
      }`
    )(body),
  checkOtp: (body) => request("post", "/deals/check-otp")(body),
  myDeals: (id) => request("get", `/deals/my/${id}`)(),
};

const eventsApi = {
  register: (body) => request("post", "/events/register")(body),
};

const storiesApi = {
  all: () => request("get", "/stories/all")(),
};

const universalApi = {
  all: ({ model }) => request("get", `/universal/${model}/all`)(),
  find: ({ model, body }) => request("post", `/universal/${model}/find`)(body),
  create: ({ model, body }) =>
    request("post", `/universal/${model}/create`)(body),
  update: ({ model, body }) =>
    request("post", `/universal/${model}/update`)(body),
};

const api = {
  usersApi,
  listingApi,
  bonusApi,
  residentialComplexApi,
  developerApi,
  katoApi,
  dealsApi,
  serverUrl,
  partnersApi,
  cloudImageUrl,
  cdnImageUrl,
  campaignDataApi,
  eventsApi,
  storiesApi,
  universalApi,
};

export default api;
