import React, { useEffect, useState } from "react";
import ProfileList from "../../components/List/ProfileList";
import Modal from "../../components/Modal/Modal";
import SearchResult from "../Search/SearchResult";
import Listing from "../Listing/Listing";
import { ModerationActions } from "./ModerationActions";
import { useGlobalMiscState } from "../../stores/misc";
import { useListing } from "../../hooks/listing";
import {
  dealTypes,
  addDealTypes,
  listingTypes,
  property_type,
} from "../../constants";
import useGlobalUserState from "../../stores/user";
import api from "../../api";
import axios from "axios";
import utils from "../../utils";

export const ListingsToModerate = ({}) => {
  const miscState = useGlobalMiscState();
  const profileListFetchTrigger = miscState.get("profileListFetchTrigger");
  const userState = useGlobalUserState();
  const user = userState.get("user");
  const [moderationModalOpen, setModerationModalOpen] = useState(false);
  const [listingId, setListingId] = useState(-1);
  const listing = useListing(listingId);
  const [katoData, setKatoData] = useState(null);
  const [timer, setTimer] = useState(null);
  const [isDelImageActive, setIsDelImageActive] = useState(false);

  const opeModerationModal = (id) => () => {
    setListingId(id);
    setModerationModalOpen(true);
  };

  const onClose = () => {
    setModerationModalOpen(false);
  };

  const handleSearchItemClick = (id) => {
    setTimer(new Date());
  };

  useEffect(() => {
    if (!moderationModalOpen) {
      miscState.set("profileListFetchTrigger", profileListFetchTrigger + 1);
    }
  }, [moderationModalOpen]);

  const loadData = async () => {
    if (listing?.kato_path) {
      const res = await axios.get(
        api.serverUrl + "/api/v1/kato/parent-array/" + listing?.kato_path
      );
      setKatoData(res?.data?.list);
    }
  };

  const deleteImage = async (img) => {
    // alert user and ask  for confirmation
    // if confirmed, delete image

    const isConfirmed = window.confirm(
      "Вы уверены, что хотите удалить это изображение?"
    );

    if (isConfirmed) {
      alert(user.token);
      const res = await axios.delete(
        `${api.serverUrl}/api/v1/listings/delete-image/${img.id}`,
        {
          headers: {
            Authorization: `Bearer ` + user.token,
          },
        }
      );
    }
  };

  useEffect(() => {
    loadData();
  }, [listing]);

  return (
    <React.Fragment>
      <ProfileList
        type='all-moderate'
        itemRender={(item, params) => {
          return (
            <div
              className='col-span-12 relative'
              onClick={opeModerationModal(item.id)}
            >
              <div onClick={() => handleSearchItemClick(item.id)}>
                <SearchResult
                  parent='ListingsToModerate'
                  disabled
                  listing={item}
                  additionalClassName='mb-2'
                />
              </div>
            </div>
          );
        }}
      />
      <Modal
        dimensions='w-full h-full xl:w-4/5 xl:h-[80%] overflow-y-scroll rounded-md'
        visible={moderationModalOpen}
        setVisible={setModerationModalOpen}
      >
        {Boolean(listingId) && (
          <React.Fragment>
            <Listing id={listingId} showSimilar={false} disableCalc={true} />
            <div className='lg:container flex flex-col mb-7'>
              {listing !== null && (
                <>
                  <div className='flex flex-row'>
                    <span className='flex-[3] font-bold'>Город/Район</span>
                    <span className='flex-[7]'>
                      {katoData?.map((i) => i.name).join(", ") || "Не найдено"}
                    </span>
                  </div>
                  <div className='flex flex-row'>
                    <span className='flex-[3] font-bold'>ID Объявления</span>
                    <span className='flex-[7]'>
                      {listing?.id ? listing.id : "Не найдено"}
                    </span>
                  </div>
                  <div className='flex flex-row'>
                    <span className='flex-[3] font-bold'>Тип операции</span>
                    <span className='flex-[7]'>
                      {
                        dealTypes.find((i) => i.value === listing?.deal_type)
                          ?.label
                      }
                    </span>
                  </div>
                  <div className='flex flex-row'>
                    <span className='flex-[3] font-bold'>Тип недвижимости</span>
                    <span className='flex-[7]'>
                      {
                        property_type.find(
                          (i) => i.value === listing?.property_type
                        )?.label
                      }
                    </span>
                  </div>
                  <div className='flex flex-row'>
                    <span className='flex-[3] font-bold'>Тип сделки</span>
                    <span className='flex-[7]'>
                      {
                        listingTypes.find(
                          (i) => i.value === listing?.listing_type
                        )?.label
                      }
                    </span>
                  </div>
                  <div className='flex flex-row'>
                    <span className='flex-[3] font-bold'>Дата публикации</span>
                    <span className='flex-[7]'>
                      {new Date(listing.createdAt).toLocaleString()}
                    </span>
                  </div>
                  <div className='flex flex-row'>
                    <span className='flex-[3] font-bold'>
                      Объявление было отредактировано
                    </span>
                    <span className='flex-[7]'>
                      {listing.createdAt === listing.updatedAt ? "Нет" : "Да"}
                    </span>
                  </div>
                  <div className='flex flex-row'>
                    <span className='flex-[3] font-bold'>
                      Дата последнего редактирования
                    </span>
                    <span className='flex-[7]'>
                      {new Date(listing.updatedAt).toLocaleString()}
                    </span>
                  </div>
                </>
              )}
            </div>
            <ModerationActions
              id={listingId}
              onClose={onClose}
              timer={timer}
              userId={user.id}
            />
            <div className='lg:container flex flex-col mb-7'>
              <h2
                className='cursor-pointer 
                text-blue-500 font-semibold text-lg mbspace
                '
                onClick={() => setIsDelImageActive((prev) => !prev)}
              >
                Удалить изображения
              </h2>
              {isDelImageActive && listing !== null && (
                <div className='flex flex-row flex-wrap'>
                  {listing?.images?.map((img) => (
                    <div
                      className='w-1/3 p-1 
                        h-[200px] relative rounded-md cursor-pointer
                      '
                      onClick={() => deleteImage(img)}
                    >
                      <img
                        src={`${utils.getImageLink(img)}`}
                        alt=''
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                        className='w-100 object-cover rounded-md'
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </React.Fragment>
        )}
        <div className='mbspace' />
      </Modal>
    </React.Fragment>
  );
};
